import React from 'react'
import useScrollToTop from '../../helpers/useScrollToTop'

const ContactUs = () => {
    useScrollToTop()
  return (
    <div className="terms-container">
    <h2>Contact Us</h2>
    <p>sen2010@gmail.com</p>
    <p>9994137221</p>
    <p>9500037221</p>
    <p>9176185709</p>
  </div>
  )
}

export default ContactUs
